import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import React from "react";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { DataTable } from "primereact/datatable";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";
import { observable } from "mobx";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { AxiosRequestConfig } from "axios";

@observer
export class AwbDetailModalComponent extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable data: any = {};
  @observable dataHistory: any = {};

  dataSource = new DataSource<any>();
  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
  }

  componentDidMount() { }

  handleShow(awbNumber) {
    this.loadData(awbNumber);
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  loadData(awbNumber) {
    this.clearData();
    this.dataSource.loading = true;
    //fill data history
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };
    // this.monitoringRestService
    //   .get("resi/detail?awbNumber=" + awbNumber, axiosConfig)
    //   .subscribe(
    //     (response) => {
    //       this.data = response.pickupData;
    //       this.dataHistory = response.historyData;
    //       this.populateDataSource();
    //     },
    //     (error) => {
    //       this.dataSource.loading = false;
    //     },
    //     () => {
    //       this.dataSource.loading = false;
    //     }
    //   );
  }

  clearData() {
    this.data = {};
    this.dataSource.setData([]);
    this.dataSource.setTotal(0);
  }

  populateDataSource() {
    if (this.dataHistory && this.dataHistory.length) {
      this.dataSource.setData(this.dataHistory);
      this.dataSource.setTotal(this.dataHistory.length);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  formatDateTime(date) {
    return date ? moment(date).utc().format("DD-MM-YYYY HH:mm") : "";
  }

  formatDate(date) {
    return date ? moment(date).utc().format("DD-MM-YYYY") : "";
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const formattedValue = colValue ? this.formatDateTime(colValue) : null;
    return formattedValue;
  };
  columnImageFormat = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    return <div style={{ textAlign: "center" }}>{ReactHtmlParser(colValue)}</div>;
  };

  render() {

    let dataShow = (this.data ?
      <div>
        <div
          className="col-md-5"
          style={{ overflow: "auto", height: "70vh" }}
        >

          <div className="row">
            <div className="col-md-4">
              <span className="font-bold">No Resi</span>
            </div>

            <div className="col-md-8">{this.data!.ref_awb_number}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">No Ref</span>
            </div>

            <div className="col-md-8">{this.data!.reference_no}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Asal</span>
            </div>

            <div className="col-md-8">{this.data!.origin_code}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Tujuan</span>
            </div>

            <div className="col-md-8">{this.data!.destination_code}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Layanan</span>
            </div>

            <div className="col-md-8">{this.data!.delivery_type}</div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kategori Paket</span>
            </div>

            <div className="col-md-8">{this.data!.parcel_category}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Isi Paket</span>
            </div>

            <div className="col-md-8">{this.data!.parcel_content}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Jumlah Paket</span>
            </div>

            <div className="col-md-8">{this.data!.parcel_qty} Pcs</div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Harga Paket</span>
            </div>

            <div className="col-md-8">IDR {parseFloat(this.data!.parcel_value || "0").toLocaleString()}</div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">COD</span>
            </div>

            <div className="col-md-8">IDR {parseFloat(this.data!.cod_value || "0").toLocaleString()}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Asuransi</span>
            </div>

            <div className="col-md-8">IDR {parseFloat(this.data!.insurance_value || "0").toLocaleString()}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Berat</span>
            </div>

            <div className="col-md-8">{this.data!.total_weight} Kg</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Partner</span>
            </div>

            <div className="col-md-8">{this.data!.partner_name}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Tax Value</span>
            </div>

            <div className="col-md-8">IDR {parseFloat(this.data!.tax_value || "0").toLocaleString()}</div>
          </div>
          <br />
          <h3>
            <u>Pengirim</u>
          </h3>
          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Nama Pengirim</span>
            </div>

            <div className="col-md-8">
              {/* {this.data!.shipper_name} */}
              Lihat Detail
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Alamat</span>
            </div>

            <div className="col-md-8">
              {/* {this.data!.shipper_address} */}
              Lihat Detail
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Provinsi</span>
            </div>

            <div className="col-md-8">{this.data!.shipper_province}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kota</span>
            </div>

            <div className="col-md-8">{this.data!.shipper_city}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kecamatan</span>
            </div>

            <div className="col-md-8">{this.data!.shipper_district}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kode Pos</span>
            </div>

            <div className="col-md-8">{this.data!.shipper_zip}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Telp</span>
            </div>

            <div className="col-md-8">
              {/* {this.data!.shipper_phone} */}
              Lihat Detail
            </div>
          </div>
          <br />
          <h3>
            <u>Penerima</u>
          </h3>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Nama Penerima</span>
            </div>

            <div className="col-md-8">
              {/* {this.data!.recipient_name} */}
              Lihat Detail
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Alamat</span>
            </div>

            <div className="col-md-8">
              {/* {this.data!.recipient_address} */}
              Lihat Detail
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Provinsi</span>
            </div>

            <div className="col-md-8">{this.data!.recipient_province}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kota</span>
            </div>

            <div className="col-md-8">{this.data!.recipient_city}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kecamatan</span>
            </div>

            <div className="col-md-8">{this.data!.recipient_district}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kode Pos</span>
            </div>

            <div className="col-md-8">{this.data!.recipient_zip}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Telp</span>
            </div>

            <div className="col-md-8">
              {/* {this.data!.recipient_phone} */}
              Lihat Detail
            </div>
          </div>

          <h3>
            <u>Data RETUR</u>
          </h3>
          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Nama Shipper</span>
            </div>

            <div className="col-md-8">{this.data!.return_shipper_name}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Alamat</span>
            </div>

            <div className="col-md-8">
              {this.data!.return_shipper_address}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Provinsi</span>
            </div>

            <div className="col-md-8">
              {this.data!.return_shipper_province}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kota</span>
            </div>

            <div className="col-md-8">{this.data!.return_shipper_city}</div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kecamatan</span>
            </div>

            <div className="col-md-8">
              {this.data!.return_shipper_district}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Kode Pos</span>
            </div>

            <div className="col-md-8">{this.data!.return_shipper_zip}</div>
          </div>



          <div className="row mt-3">
            <div className="col-md-4">
              <span className="font-bold">Telp</span>
            </div>

            <div className="col-md-8">
              {this.data!.return_shipper_phone}
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <DataTable
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            style={{ width: "100%" }}
            className="mt-1"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
          >
            <Column
              field="history_date_time"
              header="Tgl Riwayat"
              sortable={false}
              style={{ width: "120px" }}
            // body={this.columnDateFormat}
            />
            <Column
              field="status_name"
              header="Status"
              sortable={false}
              style={{ width: "180px" }}
            />
            <Column
              field="reason_name"
              header="Alasan"
              sortable={false}
              style={{ width: "140px" }}
            />
            <Column
              field="reason_note"
              header="Catatan Si Gesit"
              sortable={false}
              style={{ width: "200px" }}
            />
            <Column
              field="images"
              header="Foto POP"
              sortable={false}
              style={{ width: "200px" }}
              body={this.columnImageFormat}
            />
            <Column
              field="fullname"
              header="Karyawan"
              sortable={false}
              style={{ width: "200px" }}
            />
            <Column
              field="branch_name"
              header="Branch From"
              sortable={false}
              style={{ width: "200px" }}
            />
            <Column
              field="branch_name_assigned"
              header="Branch To"
              sortable={false}
              style={{ width: "200px" }}
            />
            <Column
              field="branch_partner_name"
              header="Gerai Partner"
              sortable={false}
              style={{ width: "200px" }}
            />
          </DataTable>
        </div>
      </div> : <div>no data found</div>);

    return (
      <div>
        <Dialog
          header="Awb Detail"
          visible={this.state.show}
          style={{
            width: "80vw",
            height: "100vh",
            overflow: "auto",
            zIndex: 100000000,
          }}
          modal={true}
          onHide={() => this.setState({ show: false })}
          maximizable
        >
          <div className="row m-1">
            {this.data ? dataShow : <div className="text-center"><h1>no data found in this awb number</h1></div>}

          </div>
          <div className="row m-5"></div>
        </Dialog>
      </div>
    );
  }
}
